<template>
  <div class="base-form">
    <el-form
      :status-icon="true"
      ref="stepFiveForm"
      :model="stepFiveformData"
      class="clearfix"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
      :rules="rules"
    >
      <div class="pull-left" style="width: 100%">
        <p class="groupTitle">{{ $t("registration.page.designs") }}</p>
      </div>
      <div style="width: 100%">
        <div style="width: 50%" class="pull-left">11
          <div style="width: 100%">
            <el-form-item
              :label="$t('label.background.the.form')"
              style="min-height: 38px"
              label-width="200px"
            >
              <el-upload
                class="avatar-uploader"
                action="/test"
                :show-file-list="false"
                :on-success="bgHandleAvatarSuccess"
                :before-upload="bgBeforeAvatarUpload"
              >
                <img
                  v-if="stepFiveformData.bgImgId"
                  :src="imgSrc(stepFiveformData.bgImgId)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" style="color: #c12217; word-break: break-word">
                  {{ $t("label.only.upload.file") }}
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div style="width: 100%">
            <el-form-item
              label="Logo"
              style="min-height: 38px"
              label-width="200px"
            >
              <el-upload
                class="avatar-uploader"
                action="/test"
                :show-file-list="false"
                :on-success="logoHandleAvatarSuccess"
                :before-upload="logoBeforeAvatarUpload"
              >
                <img
                  v-if="stepFiveformData.logoImgId"
                  :src="imgSrc(stepFiveformData.logoImgId)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" style="color: #c12217; word-break: break-word">
                  {{ $t("label.only.upload.file") }}
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div style="width: 100%">
            <el-form-item
              prop="subject"
              :label="$t('label.form.title')"
              style="min-height: 38px"
              label-width="200px"
            >
              <el-input
                v-model="stepFiveformData.subject"
                :placeholder="$t('label.form.title')"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 100%">
            <el-form-item
              :label="$t('label.introduction.the.form')"
              style="min-height: 38px"
              label-width="200px"
            >
              <el-input
                v-model="stepFiveformData.remark"
                :placeholder="$t('label.introduction.the.form')"
                clearable
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <!-- <div style="width: 50%" class="pull-left">
          <div class="formStyle">{{ $t("label.form.sample") }}</div>
          <div style="width: 100%; display: flex; justify-content: center">
            <div class="pic">
              <svg class="icon" aria-hidden="true">
                <use href=""></use>
              </svg>
            </div>
          </div>
        </div> -->
      </div>
    </el-form>
  </div>
</template>

<script>
import * as CommonObjApi from "../../../../api";

export default {
  name: "stepFive",
  data() {
    return {
      labelPosition: "right", // 标签位置
      stepFiveformData: {
        subject: "",
        remark: "",
        bgImgId: "", // 图片id
        logoImgId: "",
      },
      fileType: ["jpg", "png", "jpeg"], // 上传图片的类型
      token: this.$CCDK.CCToken.getToken(),
      rules: {
        subject: [
          {
            required: true,
            message: this.$i18n.t("label.file.enter.title"),
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  mounted() {
    if (this.$store.state.channel.actionType == "edit") {
      this.getStepFiveData();
    }
  },
  methods: {
    // 获取步骤一页面的数据
    getStepFiveData() {
      let params;
      if (this.$store.state.channel.actionType == "edit") {
        params = { id: this.$store.state.channel.itemDataId };
      } else {
        // 新建
        params = { id: "" };
      }
      CommonObjApi.stepFive(params)
        .then((res) => {
          if (res.result) {
            // 回显表单的值
            if (res.data) {
              this.stepFiveformData.subject = res.data.subject;
              this.stepFiveformData.remark = res.data.remark;
              this.stepFiveformData.bgImgId = res.data.bgimage;
              this.stepFiveformData.logoImgId = res.data.logo;
            }
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 校验
    fiveSubmitForm() {
      let validate = false;
      // 验证
      this.$refs.stepFiveForm.validate((valid) => {
        if (valid) {
          validate = true;
          // alert('submit!');
        } else {
          validate = false;
        }
      });
      return validate;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    bgHandleAvatarSuccess() {
      // this.stepFiveformData.bgimage = URL.createObjectURL(file.raw)
    },
    bgBeforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      let that = this;
      if (!isLt2M) {
        this.$message({
          message: this.$i18n.t("label.upload.file.size.limit"),
          type: "warning",
        });
        // this.$message.error(file.name + this.$i18n.t('vue_label_file_over_file_size'))
        return false;
      } else if (this.fileType.indexOf(type) === -1) {
        this.$message.error(
          this.$message.error(
            this.$i18n.t("vue_label_file_wrong_type", { type: type })
          )
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");
        CommonObjApi.uploadImg(formData)
          .then((res) => {
            if (res.result && res.data.fileid) {
              that.stepFiveformData.bgImgId = res.data.fileid;
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    logoHandleAvatarSuccess() {},
    logoBeforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      let that = this;
      if (!isLt2M) {
        this.$message({
          message: this.$i18n.t("label.upload.file.size.limit"),
          type: "warning",
        });
        // this.$message.error(file.name + this.$i18n.t('vue_label_file_over_file_size'))
        return false;
      } else if (this.fileType.indexOf(type) === -1) {
        this.$message.error(
          this.$message.error(
            this.$i18n.t("vue_label_file_wrong_type", { type: type })
          )
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");
        CommonObjApi.uploadImg(formData)
          .then((res) => {
            if (res.result && res.data.fileid) {
              that.stepFiveformData.logoImgId = res.data.fileid;
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>

<style scoped lang="scss">
.base-form {
  font-size: 12px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  font-size: 12px;
}
::v-deep .el-radio {
  color: #080707;
  cursor: pointer;
}
::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
::v-deep .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.formStyle {
}
.pic {
  width: 323px;
  height: 469px;
  img {
    width: 100%;
    height: 100%;
  }
}
.formStyle {
  width: 100%;
  font-size: 14px;
  color: #080707;
  text-align: center;
  margin-bottom: 1em;
}
</style>
