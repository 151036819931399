!<!--  -->
<template>
  <div class="">
    <div class="parent" v-if="canWrite">
      <div class="textLeft">
        <p>{{ $t("label.emailtocloudcc.name") }}</p>
      </div>
      <div class="textRight">{{ information.channel }}</div>
      <!-- <div class="textLeft"><p>类型</p></div>
      <div class="textRight">{{ information.subject }}</div> -->
      <div class="textLeft">
        <p>{{ $t("label.industrialandcommercialverification.label.url") }}</p>
      </div>
      <div class="textRight">{{ information.url }}</div>
      <div class="textLeft">
        <p>{{ $t("label.usefullife") }}</p>
      </div>
      <div class="textRight">{{ information.validdate }}</div>
      <div class="textLeft">
        <p>{{ $t("label.tag.manage.createdby") }}</p>
      </div>
      <div class="textRight">
        {{ information.createname }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
          information.createdate
        }}
      </div>
      <div class="textLeft">
        <p>{{ $t("label.tag.manage.modifiedby") }}</p>
      </div>
      <div class="textRight">
        {{ information.lastmodifyname }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
          information.lastmodifydate
        }}
      </div>
    </div>
    <div class="sourveCod">
      <span class="pull-left messageBox" v-if="canWrite">{{
        $t("label.chatter.content")
      }}</span>
      <div :class="canWrite ? 'codeBox' : ''">
        <el-input
          type="textarea"
          :autosize="{ minRows: 100, maxRows: 200 }"
          :placeholder="$t('label.tabpage.entercontent')"
          v-model="sourceCodeVal"
          :readonly="editable"
          @blur="blurChange"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceCode: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    canWrite: {
      type: Boolean,
      default: false,
    },
    information: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      sourceCodeVal: this.sourceCode,
    };
  },
  mounted() {},
  //方法集合
  methods: {
    blurChange() {
      this.$emit("sourceCodeChange", this.sourceCodeVal);
    },
  },
};
</script>

<style lang='scss' scoped>
.parent {
  font-size: 14px;
}
.messageMain {
  position: absolute;
  left: 140px;
  margin-right: 40px;
}
.codeBox {
  width: 85%;
  float: right;
}
.messageBox {
  padding: 12px 5px 12px 0px;
  font-size: 14px;
}
textarea {
  resize: none;
}
p {
  font-family: "Microsoft yahei";
}
.textLeft {
  width: 15%;
  height: 40px;
  float: left;
}

.textRight {
  height: 40px;
  width: 85%;
  margin-left: 15%;
  overflow: hidden;
}
</style>