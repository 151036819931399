<template>
  <div class="viewButtons">
    <el-row>
      <el-form
        ref="objForms"
        :model="stepFourFormData"
        label-position="left"
        size="small"
      >
        <div class="pull-left" style="width: 100%">
          <p class="groupTitle">
            {{ $t("registration.page.selected.fields") }}
          </p>
        </div>
        <el-col :span="24">
          <el-form-item>
            <oneShuttle
              :key="num"
              ref="oneShuttle"
              :viewUnselectedFieldList="viewNoSelectedFieldList"
              :viewSelectedFieldArr="viewSelectedFieldList"
              @selFun="getSelFun"
            />
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import oneShuttle from '@/views/marketList/marketingChannel/component/shuttleBox/oneShuttle.vue'
export default {
  name: 'stepFour',
  components: {
    oneShuttle,
  },
  props: {
    stepFourNoSelectList: {
      type: Array,
      default: ()=>[],
    },
    stepFourSelectedList: {
      type: Array,
      default: ()=>[],
    },
  },
  watch: {
    stepFourNoSelectList: {
      immediate: true, //
      handler(val) {
        this.viewNoSelectedFieldList = val
        this.num++
      },
    },
    stepFourSelectedList: {
      immediate: true, //
      handler(val) {
        this.viewSelectedFieldList = val
        this.num++
      },
    },
  },
  computed: {},
  data() {
    return {
      formLabelWidth: '118px',
      viewSelectedFieldList: [], //已选字段
      viewNoSelectedFieldList: [], //未选字段
      stepFourFormData: {},
      num: 1, // 为了子组件能够实时获取到数据
    }
  },
  mounted() {
    this.getStepFourData()
  },
  methods: {
    // 获取步骤四页面的数据
    getStepFourData() {
      this.viewNoSelectedFieldList = this.stepFourNoSelectList
      this.viewSelectedFieldList = this.stepFourSelectedList
    },
    //获取可选成员
    getSelFun() { },
    // 获取用户操作后，步骤四的备选和已选列表的数据 备选即不必填字段，已选即必填字段，  调用注册页面接口需要
    getStepFourNoSelectChangeList() {
      this.$refs.oneShuttle.curGroupLists()
      let stepFourNoSelectFieldList = this.$refs.oneShuttle.originFields;
      stepFourNoSelectFieldList.forEach(item => {
        item.required = "0"
      })
      return stepFourNoSelectFieldList
    },
    getStepFourSelectedChangeList() {
      // // 步骤4的已选列表
      this.$refs.oneShuttle.curGroupLists()
      let stepFourSelectedFieldList = this.$refs.oneShuttle.viewSelectedFieldList
      stepFourSelectedFieldList.forEach(item => {
        item.required = "1"
      })
      return stepFourSelectedFieldList
    },
  },
}
</script>

<style lang="scss" scoped>
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
</style>
