<!-- 列表上方按钮 -->
<template>
  <div class="viewButtons">
    <!-- 视图弹窗 -->
    <!--        <div style="text-align: center">-->
    <!--            <i-->
    <!--                    v-if="loading"-->
    <!--                    class="el-icon-loading"-->
    <!--                    style="font-size: 20px; margin-top: 200px"-->
    <!--            >-->
    <!--            </i>-->
    <!--        </div>-->
    <el-row>
      <el-form
        ref="objForms"
        :model="stepTwoFormData"
        :label-width="formLabelWidth"
        label-position="left"
        size="small"
      >
        <el-col :span="24">
          <div class="screen_s">
            <el-form-item>
              <span style="width: 21px"></span>
              <div class="select_one fieldTextStyle">
                {{ $t("setup.layout.label.selectedfield") }}
              </div>
              <p class="equalP"></p>
              <div class="select_two fieldTextStyle">
                <div>{{ $t("label.tabpage.value") }}</div>
              </div>
              <div style="width: 100px"></div>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <div
            class="screen_s"
            v-for="(ev, index) in stepTwoFormData.leadDefaultValues"
            :key="index"
          >
            <el-form-item>
              <span style="width: 21px">{{ index + 1 }}</span>
              <!-- placeholder="选择字段" -->
              <el-select
                v-model="ev.fieldid"
                :placeholder="$t('label.selectfield')"
                class="select_one"
                filterable
                clearable
                @change="selectChange($event, index)"
              >
                <el-option
                  v-for="item in selectFieldList"
                  :key="item.datafieldRef"
                  :label="item.labelName"
                  :value="item.datafieldRef"
                ></el-option>
              </el-select>
              <p class="equalP">{{ $t("label.tabpage.equalz") }}</p>
              <div class="select_two">
                <el-input
                  :placeholder="$t('label.please.fill.out')"
                  v-model="ev.dvalue"
                >
                </el-input>
              </div>
              <div style="width: 100px">
                <span
                  class="btn_s"
                  @click="dels(index)"
                  v-if="stepTwoFormData.leadDefaultValues.length !== 1"
                  >-</span
                >
                <span
                  class="btn_s"
                  @click="adds"
                  v-if="stepTwoFormData.leadDefaultValues.length - 1 === index"
                  >+</span
                >
              </div>
            </el-form-item>
          </div>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import * as CommonObjApi from '../../../../api'

export default {
  name: 'stepTwo',
  data() {
    return {
      loading: true,
      formLabelWidth: '118px',
      dataId: 1,
      //初始数据
      initData: {
        fieldid: '', // select 绑定的值
        dvalue: '', // input绑定的值
      },
      stepTwoFormData: {
        leadDefaultValues: [
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
          {
            fieldid: '', // select 绑定的值
            dvalue: '', // input绑定的值
          },
        ],
      },
      selectFieldList: [], // 下拉框的数据 从后端获取
      projectTasksObjectApi: '',
    }
  },
  mounted() {
    this.getStepTwoData()
  },
  created() { },
  methods: {
    // 获取步骤二页面的数据
    getStepTwoData() {
      let params
      if (this.$store.state.channel.actionType == 'edit') {
        params = { id: this.$store.state.channel.itemDataId }
      } else {
        // 新建
        params = {}
      }
      CommonObjApi.stepTwo(params)
        .then((res) => {
          if (res.result) {
            this.selectFieldList = res.data.fieldList
            if (this.$store.state.channel.actionType == 'edit') {
              // 回显表单的值  将空的放在数组后面
              res.data.leaddvalue.forEach((item, index) => {
                if ((item.fieldid == "" && item.dvalue == "") || (item.fieldid == "null" && item.dvalue == "null")) {
                  let obj = item;
                  res.data.leaddvalue.splice(index, 1);// 第i位删除1项
                  res.data.leaddvalue.push(obj)
                } else {
                  let obj = item;
                  res.data.leaddvalue.splice(index, 1);// 第i位删除1项
                  res.data.leaddvalue.unshift(obj)
                }
              })
              if (res.data.leaddvalue.length >= 4) {
                this.stepTwoFormData.leadDefaultValues = res.data.leaddvalue
              } else {
                // 如果长度小于4那么补齐
                for (let i = 0; i <= 4 - res.data.leaddvalue.length + 1; i++) {
                  if (res.data.leaddvalue.length < 4) {
                    res.data.leaddvalue.push({
                      fieldid: '', // select 绑定的值
                      dvalue: '', // input绑定的值
                    })
                  }
                }
                this.stepTwoFormData.leadDefaultValues = res.data.leaddvalue
              }

            }
          }
        })
        .catch(() => {
          return false
        })
    },
    //新增
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1
        this.stepTwoFormData.leadDefaultValues.push({
          ...this.initData,
          id: this.dataId,
        })
      } else {
        return
      }
    },
    //删除
    dels(id) {
      this.dataId = id
      this.stepTwoFormData.leadDefaultValues.splice(id, 1)
    },
    //根据筛选字段判断值
    selectChange() { },
  },
  watch: {
    // 获取路由
    $route: {
      handler: function (route) {
        // 获取到项目任务页面对象api
        this.projectTasksObjectApi = route.params.objectApi
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.equalP {
  width: 15%;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #080707;
  margin-bottom: 0;
}

.el-button-group {
  /*display: inline-block;*/
  vertical-align: baseline;
}

.viewButtons {
  margin: 0 auto;
  ::v-deep .editableCell {
    height: 33px;
  }

  .el-button-group {
    vertical-align: baseline;
  }
}
.screen_s > div {
  margin-bottom: 0 !important;
}

::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 38px;
    overflow-y: hidden;
  }

  .el-input .el-input__inner {
    height: 32px !important;
  }

  .el-tag {
    margin: 0 5px !important;
  }
}

.screen_s ::v-deep .el-form-item__content {
  display: flex;
  margin-left: 133px !important;
  align-items: center;
}

.el-form-item {
  margin-bottom: 0;
}

.select_one {
  /*width: 280px;*/
  width: 35%;
  margin: 6px;
  height: 32px;
}

.select_two {
  width: 35%;
  margin: 6px 12px 6px 6px;
  height: 32px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}
.fieldTextStyle {
  text-align: center;
  font-size: 14px;
  color: #080707;
}
</style>
