<template>
  <div class="base-form">
    <div class="pull-left" style="width: 100%">
      <p class="groupTitle">{{ $t("registration.page.preview") }}</p>
    </div>
    <div class="contentBox">
      <div class="mainBox">
        <div
          class="bcImg"
          v-if="
            sixStepFormDataList.bgImgId &&
            sixStepFormDataList.bgImgId.length != 0
          "
          :style="{
            'background-image':
              'url(' + imgSrc(sixStepFormDataList.bgImgId) + ')',
          }"
        ></div>
        <div
          class="logoImg"
          v-if="
            sixStepFormDataList.logoImgId &&
            sixStepFormDataList.logoImgId.length != 0
          "
        >
          <img
            :src="imgSrc(sixStepFormDataList.logoImgId)"
            style="max-width: 100%; max-height: 100%"
          />
        </div>
        <!--            <img class="logoImg" :src="imgSrc(sixStepFormDataList.logoImgId)">-->
        <div class="tit">
          <p>{{ sixStepFormDataList.subject }}</p>
        </div>
        <div class="desText" v-if="sixStepFormDataList.remark">
          {{ sixStepFormDataList.remark }}
        </div>
        <el-form>
          <div
            class="registeredItem"
            v-for="(ev, index) in sixStepFormDataList.fieldList"
            :key="index"
          >
            <el-form-item
              :key="ev.id"
              :required="ev.required == '1' ? true : false"
              :label="ev.labelname"
              style="min-height: 38px"
              label-width="125px"
            >
              <el-input
                v-if="
                  ev.fieldtype == 'S' ||
                  ev.fieldtype == 'ct' ||
                  ev.fieldtype == 'E' ||
                  ev.fieldtype == 'H' ||
                  ev.fieldtype == 'SCORE'
                "
                v-model="ev.dvalue"
                clearable
              ></el-input>
              <el-select
                v-model="ev.dvalue"
                :placeholder="$t('label.emailtocloudcc.select1')"
                v-if="ev.fieldtype == 'L'"
              >
                <el-option
                  v-for="item in ev.selectList"
                  :key="item.codekey"
                  :label="item.codevalue"
                  :value="item.codevalue"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="ev.fieldtype == 'X'"
                type="textarea"
                :rows="2"
                v-model="ev.dvalue"
              >
              </el-input>
              <el-switch v-if="ev.fieldtype == 'B'" v-model="ev.dvalue">
              </el-switch>
              <el-checkbox-group
                v-if="ev.fieldtype == 'Q'"
                v-model="checkModel"
              >
                <el-checkbox
                  style="display: block"
                  v-for="item in ev.selectList"
                  :key="item.codekey"
                  :label="item.codevalue"
                  :value="item.codevalue"
                ></el-checkbox
              ></el-checkbox-group>
            </el-form-item>
          </div>
          <div class="buttonBox">
            <el-form-item>
              <el-button type="primary" class="commitBt">{{
                $t("label.forecast.customforecast.button.submit")
              }}</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/utils/brower'

export default {
  components: {},
  props: {
    sixStepFormData: {
      type: Object,
      default: () => {
        return {
          "pagecontent": '',
          "bgImgId": "1619406355496ilg",
          "subject": "标题哦",
          "logoImgId": "16194326391698TV",
          "remark": "简介哦",
          "fieldList": [
            {
              "dvalue": '',
              "fieldname": "name",
              "id": "ffe201200108756NyYr4",
              "fieldtype": "S",
              "labelname": "成员名称",
              "required": "1"
            },
          ]

        }
      }
    }
  },
  data() {
    return {
      checkModel: [],
      token: getCookie('binding'),
      dvalue: '',
      sixStepFormDataList: {}
    }
  },
  mounted() {
    this.getStepSixData();
    // 
  },
  computed: {},
  watch: {
    sixStepFormData: {
      immediate: true, //
      handler(val) {
        this.sixStepFormDataList = val
      },
    }
  },
  //方法集合
  methods: {
    // 获取步骤一页面的数据
    getStepSixData() {
      this.sixStepFormDataList = this.sixStepFormData
    },

    //提交
    submitMethod() {

    },
    imgSrc(id) {
      return `${this.$cookies.get(
        'domainName'
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`
    },
  },
}

</script>
<style lang='scss' scoped>
.base-form {
  /*text-align: center;*/
  font-size: 12px;
  position: relative;
}
.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.contentBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainBox {
  width: 419px;
  min-height: 548px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  /*padding-bottom: 100px;*/
  margin-bottom: 15px;
  margin-top: 30px;
  position: relative;
}
.desText {
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  word-wrap: break-word;
  word-break: normal;
  margin: 0 20px 20px 20px;
  // overflow: auto;
  min-height: 62px;
}
.registeredItem {
  float: left;
  width: 100%;
  max-width: 100%;
  padding: 0 35px 0 0;
}
.tit {
  font-family: SourceHanSansCN-Medium;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
  line-height: 24px;
  margin: 15px auto 14px;
  text-align: center;
}
.bcImg {
  width: 420px;
  height: 98px;
  /*border: 2px solid red;*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.logoImg {
  width: 420px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  font-size: 12px;
}
::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
::v-deep .el-select {
  width: 100%;
}
.buttonBox {
  text-align: center;
  width: 100%;
  float: left;
  /*position: absolute;*/
  left: 0;
  right: 0;
  bottom: 0;
}
</style>