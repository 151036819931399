<template>
  <!-- 新增/修改记录 -->
  <div class="stepDialog">
    <el-dialog
      v-if="isShowDialogD"
      class="abow_dialog"
      width="70%"
      top="3%"
      style="height: 80%; overflow: visible"
      :title="dialogTitle"
      :visible.sync="isShowDialogD"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancel"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <!--步骤1-->
        <stepOne ref="oneStep" v-show="currentStep === 1"> </stepOne>
        <stepTwo ref="twoStep" v-show="currentStep === 2"> </stepTwo>
        <stepThr ref="thrStep" v-show="currentStep === 3"> </stepThr>
        <stepFour
          :key="num"
          :stepFourNoSelectList="stepFourNoSelectList"
          :stepFourSelectedList="stepFourSelectedList"
          ref="fourStep"
          v-show="currentStep === 4"
        >
        </stepFour>
        <stepFive ref="fiveStep" v-show="currentStep === 5"> </stepFive>
        <stepSix
          ref="sixStep"
          :key="count"
          :sixStepFormData="sixStepFormData"
          v-show="currentStep === 6"
        >
        </stepSix>
        <stepSeven ref="sevenStep" v-show="currentStep === 7" @save="save">
        </stepSeven>
      </div>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <span class="step">
          <span>{{ currentStep }}</span>
          /
          <span style="margin-right: 20px">{{ totalStep }}</span>
        </span>
        <el-button
          v-show="currentStep === 6"
          size="mini"
          type="primary"
          @click="showCode"
        >
          {{ $t("label.source.code") }}
        </el-button>
        <el-button
          v-show="currentStep !== 1"
          size="mini"
          type="primary"
          @click="preStep"
        >
          {{ $t("label.import.page1.laststep") }}
        </el-button>
        <el-button
          v-show="currentStep !== 7"
          size="mini"
          type="primary"
          @click="nextStep"
        >
          {{ $t("label.import.page1.nextstep") }}
        </el-button>
        <el-button
          v-show="currentStep === 7"
          size="mini"
          type="primary"
          @click="complete"
        >
          {{ $t("label.emailsync.button.cancel") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 查看源码弹窗 -->
    <el-dialog
      :visible.sync="sourceCodeFlg"
      :title="$t('label.source.code')"
      top="3%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <souceCode
        :sourceCode.sync="sourceCode"
        :editable="false"
        :key="number"
        @sourceCodeChange="sourceCodeChange"
      ></souceCode>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "@/mixin/bus.js";
import stepOne from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepOne.vue";
import stepTwo from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepTwo.vue";
import stepThr from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepThr.vue";
import stepFour from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepFour.vue";
import stepFive from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepFive.vue";
import stepSix from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepSix.vue";
import stepSeven from "@/views/marketList/marketingChannel/component/stepDialog/stepContent/stepSeven.vue";
import souceCode from "@/views/marketList/marketingChannel/component/sourceCode/index.vue";
import * as CommonObjApi from "../../../api";

export default {
  name: "index",
  components: {
    stepOne,
    stepTwo,
    stepThr,
    stepFive,
    stepSix,
    stepSeven,
    souceCode,
    stepFour,
  },
  props: {
    isShowDialog: {
      // 是否显示弹框
      type: Boolean,
      default: false,
    },
    dialogTitle:{
      type:String,
      default:""
    }
  },
  computed: {
    isShowDialogD: {
      get() {
        return this.isShowDialog;
      },
      set(val) {
        this.$emit("update:isShowDialog", val);
      },
    },
  },
  data() {
    return {
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      currentStep: 1,
      totalStep: 7,
      stepFourNoSelectList: [],
      stepFourSelectedList: [],
      num: 1,
      count: 0,
      number: 2,
      registeredPageRequiredlist: [],
      registeredPageNoRequiredlist: [],
      fields: [], // 生成注册页面的字段信息
      sourceCode: "", // 源码
      sourceCodeFlg: false,
      sixStepFormData: {}, // 第六步的数据
    };
  },
  mounted() {
    Bus.$on("windowResize", this.ebFn);
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.ebFn);
  },
  methods: {
    ebFn (offsetHeight){
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    // 弹窗关闭
    cancel() {
      this.$emit("closeDialog");
      this.currentStep = 1;
    },
    // 上一步
    preStep() {
      if (this.currentStep !== 1) {
        this.currentStep--;
      } else {
        this.currentStep = 1;
      }
    },
    nextStep() {
      //
      if (this.currentStep === 1) {
        //校验第一步的必填项
        if (this.$refs.oneStep.submitForm() === false) {
          return;
        }
      }
      if (this.currentStep !== 7) {
        if (this.currentStep === 3) {
          //
          // 第三步点击下一步的时候，获取步骤三中穿梭框选中的数据，给步骤四穿梭框的备选列表使用
          this.stepFourNoSelectList =
            this.$refs.thrStep.getStepFourNoSelectInitList();
          this.stepFourSelectedList =
            this.$refs.thrStep.getStepFourSelectedInitList();
          this.num++;
          //
          //
        } else if (this.currentStep === 5) {
          // 当前是第五步，点击下一步的时候，拿到数据调用预览接口
          if (this.$refs.fiveStep.fiveSubmitForm() === false) {
            return;
          }
          let params = {};
          params.id = "";
          this.fields = [];
          this.registeredPageRequiredlist =
            this.$refs.fourStep.getStepFourSelectedChangeList();
          this.registeredPageNoRequiredlist =
            this.$refs.fourStep.getStepFourNoSelectChangeList();
          this.registeredPageRequiredlist.forEach((item) => {
            this.fields.push({
              fieldname: item.fieldname,
              required: item.required,
            });
          });
          this.registeredPageNoRequiredlist.forEach((item) => {
            this.fields.push({
              fieldname: item.fieldname,
              required: item.required,
            });
          });
          params.fields = JSON.stringify(this.fields);
          // 获取源码
          CommonObjApi.channelPreview({
            ...params,
            ...this.$refs.fiveStep.stepFiveformData,
          })
            .then((res) => {
              if (res.result) {
                //
                this.sourceCode = decodeURIComponent(res.data.pagecontent);
                // 给每条数据中添加一个空值，用于步骤留页面表单v-model值得绑定
                res.data.fieldList.forEach((item) => {
                  item.dvalue = "";
                });
                this.sixStepFormData = res.data; // 第六步页面所需的数据
                this.count++;
                this.number++;
              }
            })
            .catch(() => {
              return false;
            });
          //
          //
          //
        }
        this.currentStep++;
      }
    },
    //源代码查看
    showCode() {
      this.sourceCodeFlg = true;
    },
    // 关闭弹窗
    handleClose() {
      this.sourceCodeFlg = false;
    },
    sourceCodeChange(val) {
      this.sourceCode = val;
      //
    },
    save() {
      //
      // 等于7的时候是完成    关闭弹框  调用保存接口
      if (this.currentStep === 7) {
        let params = {};
        if (this.$store.state.channel.actionType == "edit") {
          params.id = this.$store.state.channel.itemDataId; // 营销渠道id
        } else {
          // 新建
          params.id = "";
        }
        params.campaignId = this.$store.state.channel.campaignId; // 市场活动id
        params.fields = JSON.stringify(this.fields); // 生成注册页面的字段信息
        params.leadDefaultValues = JSON.stringify(
          this.$refs.twoStep.stepTwoFormData.leadDefaultValues
        ); // 潜在客户默认值的设置
        params.pagecontent = encodeURIComponent(this.sourceCode); // 源码
        // 步骤一种的url和图片是一个参数，所以需要处理一下
        if (this.$refs.oneStep.stepOneformData.rtnType == "image") {
          this.$refs.oneStep.stepOneformData.rtnurl =
            this.$refs.oneStep.stepOneformData.imgId;
          delete this.$refs.oneStep.stepOneformData.imgId;
        } else {
          delete this.$refs.oneStep.stepOneformData.imgId;
        }
        let objParams = {
          ...params,
          ...this.$refs.oneStep.stepOneformData,
          ...this.$refs.fiveStep.stepFiveformData,
          ...this.$refs.sevenStep.stepSevenformData,
        };
        // 营销渠道保存
        CommonObjApi.channelSave(objParams)
          .then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label.search.saveok"),
              });
              if (this.$refs.sevenStep.stepSevenformData.isusing) {
                // 跳转邀约页面
                this.$router.push({
                  name: `invitation`,
                  params: {
                    id: res.data.id, // 营销渠道id
                    objId: "user",
                    objectApi: "campain",
                    prefix: "005",
                    type: "table",
                  },
                });
                // window.open(`${this.$cookies.get(
                //   'domainName'
                // )}/user.action?m=query&campaignid=${res.data.campaignId}&registerid=${res.data.id}`)
              } else {
                // 跳转详情页面
                this.$router.push({
                  path: `/commonObjects/detail/${res.data.campaignId}/DETAIL`,
                });
              }
              this.$emit("closeDialog");
              this.currentStep = 1;
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    complete() {
      this.$emit("closeDialog");
      this.currentStep = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
.step {
  font-size: 12px;
  color: #080707;
}
</style>
