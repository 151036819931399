<template>
  <div class="viewButtons">
    <el-row>
      <el-form
        ref="objForms"
        :model="stepThreeFormData"
        label-position="left"
        size="small"
      >
        <div class="pull-left" style="width: 100%">
          <p class="groupTitle">
            {{ $t("registration.page.field.selection") }}
          </p>
        </div>
        <el-col :span="24">
          <el-form-item>
            <oneShuttle
              :key="num"
              ref="oneShuttle"
              :viewUnselectedFieldList="stepThrNoSelectList"
              :viewSelectedFieldArr="stepThrSelectedList"
              @selFun="getSelFun"
            />
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import * as CommonObjApi from "../../../../api";
import oneShuttle from "@/views/marketList/marketingChannel/component/shuttleBox/oneShuttle.vue";

export default {
  name: "stepThr",
  components: {
    oneShuttle,
  },
  mounted() {
    this.getStepThreeData();
  },
  data() {
    return {
      formLabelWidth: "118px",
      stepThreeFormData: {},
      num: 1, // 父组件给子组件动态传值，子组件获取不到所以需要加一个开关
      allFields: [], // 所有字段
      selectedFields: [], // 已选id
      //已选字段列表
      stepThrSelectedList: [],
      //未选字段列表
      stepThrNoSelectList: [],
      stepFourRequiredIds: [], // 步骤四的已选ids
      stepFourNoSelectList: [], // 步骤四的备选
      stepFourSelectedList: [], // 步骤四的已选
    };
  },
  methods: {
    // 获取步骤一页面的数据
    getStepThreeData() {
      let params;
    
      if (this.$store.state.channel.actionType == "edit") {
        params = { id: this.$store.state.channel.itemDataId };
      } else {
        // 新建
        params = { id: "" };
      }
      CommonObjApi.stepThree(params)
        .then((res) => {
          if (res.result) {
            this.num++; // 为了给子组件动态传参可以生效
            this.allFields = res.data.allFields; // 所有字段
            this.selectedFields = res.data.selectedFields; // 已选字段id
            this.stepThrSelectedList = [];
            this.stepThrNoSelectList = [];
            // // 循环所有数据，将已选和未选从所有数据中拿出来
            // 步骤三的备选
            this.stepThrNoSelectList = [...this.allFields].filter((x) =>
              [...this.selectedFields].every((y) => y.fieldname !== x.fieldname)
            ); // 取差集
            // 步骤三的已选
            this.stepThrSelectedList = [...this.allFields].filter((x) =>
              [...this.selectedFields].some((y) => y.fieldname === x.fieldname)
            ); // 取并集
            // 步骤四的必选Ids
            this.stepFourRequiredIds = [];
            // 取出required作为步骤4的必选
            this.stepFourRequiredIds = this.selectedFields.filter((item) => {
              return item.required == 1;
            });
            // 
            // 
            // 
            // 
            // 
            // 
          }
        })
        .catch(() => {
          return false;
        });
    },
    //获取可选成员
    getSelFun() {},
    // 根据步骤三的数据进行处理，过滤步骤四的初始字段
    getStepFourNoSelectInitList() {
      // 步骤4的备选列表
      this.$refs.oneShuttle.curGroupLists();
      let stepFourAllFieldList = []; // 步骤四的所有列表
      let stepFourNoSelectList = []; // 步骤四的备选列表
      stepFourAllFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      // 从所有列表中过滤出备选
      stepFourNoSelectList = [...stepFourAllFieldList].filter((x) =>
        [...this.stepFourRequiredIds].every((y) => y.fieldname !== x.fieldname)
      );
      return stepFourNoSelectList;
    },
    getStepFourSelectedInitList() {
      // 步骤4的已选列表
      this.$refs.oneShuttle.curGroupLists();
      let stepFourAllFieldList = []; // 步骤四的所有列表
      let stepFourSelectedList = []; // 步骤四的已选列表
      stepFourAllFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      // 从所有列表中过滤出已选
      stepFourSelectedList = [...stepFourAllFieldList].filter((x) =>
        [...this.stepFourRequiredIds].some((y) => y.fieldname === x.fieldname)
      );
      return stepFourSelectedList;
    },
  },
};
</script>

<style lang="scss" scoped>
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
</style>
