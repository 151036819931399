var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-form"},[_c('div',{staticClass:"pull-left",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"groupTitle"},[_vm._v(_vm._s(_vm.$t("registration.page.preview")))])]),_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"mainBox"},[(
          _vm.sixStepFormDataList.bgImgId &&
          _vm.sixStepFormDataList.bgImgId.length != 0
        )?_c('div',{staticClass:"bcImg",style:({
          'background-image':
            'url(' + _vm.imgSrc(_vm.sixStepFormDataList.bgImgId) + ')',
        })}):_vm._e(),(
          _vm.sixStepFormDataList.logoImgId &&
          _vm.sixStepFormDataList.logoImgId.length != 0
        )?_c('div',{staticClass:"logoImg"},[_c('img',{staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":_vm.imgSrc(_vm.sixStepFormDataList.logoImgId)}})]):_vm._e(),_c('div',{staticClass:"tit"},[_c('p',[_vm._v(_vm._s(_vm.sixStepFormDataList.subject))])]),(_vm.sixStepFormDataList.remark)?_c('div',{staticClass:"desText"},[_vm._v(" "+_vm._s(_vm.sixStepFormDataList.remark)+" ")]):_vm._e(),_c('el-form',[_vm._l((_vm.sixStepFormDataList.fieldList),function(ev,index){return _c('div',{key:index,staticClass:"registeredItem"},[_c('el-form-item',{key:ev.id,staticStyle:{"min-height":"38px"},attrs:{"required":ev.required == '1' ? true : false,"label":ev.labelname,"label-width":"125px"}},[(
                ev.fieldtype == 'S' ||
                ev.fieldtype == 'ct' ||
                ev.fieldtype == 'E' ||
                ev.fieldtype == 'H' ||
                ev.fieldtype == 'SCORE'
              )?_c('el-input',{attrs:{"clearable":""},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}}):_vm._e(),(ev.fieldtype == 'L')?_c('el-select',{attrs:{"placeholder":_vm.$t('label.emailtocloudcc.select1')},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}},_vm._l((ev.selectList),function(item){return _c('el-option',{key:item.codekey,attrs:{"label":item.codevalue,"value":item.codevalue}})}),1):_vm._e(),(ev.fieldtype == 'X')?_c('el-input',{attrs:{"type":"textarea","rows":2},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}}):_vm._e(),(ev.fieldtype == 'B')?_c('el-switch',{model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}}):_vm._e(),(ev.fieldtype == 'Q')?_c('el-checkbox-group',{model:{value:(_vm.checkModel),callback:function ($$v) {_vm.checkModel=$$v},expression:"checkModel"}},_vm._l((ev.selectList),function(item){return _c('el-checkbox',{key:item.codekey,staticStyle:{"display":"block"},attrs:{"label":item.codevalue,"value":item.codevalue}})}),1):_vm._e()],1)],1)}),_c('div',{staticClass:"buttonBox"},[_c('el-form-item',[_c('el-button',{staticClass:"commitBt",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("label.forecast.customforecast.button.submit")))])],1)],1)],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }