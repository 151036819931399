<template>
  <div class="base-form">
    <el-form
      :status-icon="true"
      ref="stepOneForm"
      :model="stepOneformData"
      class="clearfix"
      :rules="rules"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
    >
      <div class="pull-left" style="width: 100%">
        <p class="groupTitle">{{ $t("page.layout.binfo") }}</p>
      </div>
      <div class="pull-left" style="width: 50%">
        <el-form-item
          :label="$t('label.channel.name')"
          prop="channel"
          style="min-height: 38px"
          label-width="200px"
        >
          <el-input
            v-model="stepOneformData.channel"
            :placeholder="$t('label.channel.name')"
            clearable
          ></el-input>
        </el-form-item>
      </div>
      <div class="pull-left" style="width: 50%">
        <el-form-item
          :label="$t('label.deadline')"
          prop="validdate"
          style="min-height: 38px"
          label-width="200px"
        >
          <el-date-picker
            v-model="stepOneformData.validdate"
            clearable
            type="date"
            :placeholder="$t('label.tabpage.selectdate')"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="pull-left" style="width: 50%">
        <el-form-item
          :label="$t('label.campagin.roi.sjcb')"
          style="min-height: 38px"
          label-width="200px"
          ><el-input-number
            v-model="stepOneformData.sjcb"
            :placeholder="$t('label.campagin.roi.sjcb')"
            clearable
            style="width: 100%"
            :precision="2"
            :controls="false"
          ></el-input-number>
        </el-form-item>
      </div>
      <div class="pull-left" style="width: 50%">
        <el-form-item
          :label="$t('label.channel.activity.member.owner')"
          style="min-height: 38px"
          label-width="200px"
        >
          <el-select
            v-model="stepOneformData.leadowner"
            :placeholder="$t('label.please.select.channel')"
          >
            <el-option
              v-for="item in leadownerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="pull-left" style="width: 100%">
        <el-form-item
          :label="$t('label.tabpage.cue')"
          style="min-height: 38px"
          label-width="200px"
        >
          <div class="successTipItemBox">
            <div class="successTipItem mt15">
              <div class="successTipRadio">
                <el-radio
                  v-model="stepOneformData.rtnType"
                  label="url"
                  @change="urlChange"
                  >{{ $t("label.web.link") }}</el-radio
                >
              </div>
              <el-input
                v-model="stepOneformData.rtnurl"
                style="height: 30px"
                :disabled="isUrlDisabled"
              ></el-input>
            </div>
            <div class="successTipItem mt15">
              <div class="successTipRadio">
                <el-radio
                  v-model="stepOneformData.rtnType"
                  label="image"
                  @change="imgChange"
                  >{{
                    $t("button_partner_workspace_login_logo_upload")
                  }}</el-radio
                >
              </div>
              <div class="upload">
                <el-upload
                  :disabled="isImgDisabled"
                  ref="upload"
                  class="upload-demo"
                  action="/test"
                  :before-upload="beforeUpload"
                  :on-success="handleSuccess"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                >
                  <el-button
                    slot="trigger"
                    size="mini"
                    :disabled="isImgDisabled"
                    >{{ $t("label.campaign.channel.upload.img") }}</el-button
                  >
                  <el-button
                    class="ml15"
                    size="mini"
                    :disabled="isImgDisabled"
                    @click="clearPic"
                    >{{ $t("label.imgfield.remove") }}</el-button
                  >
                  <br />
                  <a
                    slot="tip"
                    style="color: #c12217; font-size: 12px; margin-left: 8px"
                    >{{ $t("label.campaign.channel.img.remark") }}</a
                  >
                  <!-- <a class="aStyle ml15" @click="lookSample('sample')">{{
                    $t("label.searchlayout.view.example")
                  }}</a> -->
                </el-upload>
              </div>
            </div>
            <div
              class="picBox"
              v-if="stepOneformData.imgId && stepOneformData.imgId.length != 0"
            >
              <img
                :src="imgSrc(stepOneformData.imgId)"
                style="max-width: 100%; max-height: 100%"
              />
            </div>

            <div class="successTipItem">
              <div class="successTipRadio">
                <el-radio
                  v-model="stepOneformData.rtnType"
                  label="ticket"
                  @change="ticketChange"
                  >{{ $t("label.campaign.channel.input.ticket") }}</el-radio
                >
              </div>
              <a class="aStyle" @click="lookSample('ticket')">{{
                $t("label.searchlayout.view.example")
              }}</a>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="pull-left" style="width: 50%; word-break: break-word">
        <el-form-item
          :label="$t('label.members.sign.notify')"
          style="min-height: 38px"
          label-width="200px"
        >
          <el-radio v-model="stepOneformData.isinformowner" :label="true">{{
            $t("label.checkbox.true")
          }}</el-radio>
          <el-radio v-model="stepOneformData.isinformowner" :label="false">{{
            $t("label.tabpage.noz")
          }}</el-radio>
        </el-form-item>
      </div>
      <div class="pull-left" style="width: 50%; word-break: break-word">
        <el-form-item
          :label="$t('label.marketing.channel.default')"
          style="min-height: 38px"
          label-width="200px"
        >
          <el-checkbox
            :value="!Boolean(stepOneformData.isactivity)"
            @change="
              () => (stepOneformData.isactivity = !stepOneformData.isactivity)
            "
            >{{ $t("label.deactivate") }}</el-checkbox
          >
        </el-form-item>
      </div>
    </el-form>
    <!--        查看示例dialog-->
    <el-dialog
      style="height: 70%; overflow: visible"
      top="3%"
      :title="$t('label.searchlayout.view.example')"
      :visible.sync="isShowSample"
      width="30%"
      :show-close="true"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
    </el-dialog>
    <!--查看入场券dialog-->
    <el-dialog
      style="height: 70%; overflow: visible"
      top="3%"
      :title="$t('label.campaign.channel.ticket.title')"
      :visible.sync="isShowTicket"
      width="30%"
      :show-close="true"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <div class="ticketBox">
        <div class="tag">{{ $t("label.campaign.channel.ticket.title") }}</div>
        <div class="box_top">
          <div class="top_title">
            <h3>{{ $t("label.financial.services.seminar") }}</h3>
            <p style="padding-bottom: 10px">{{ $t("label.specify.time") }}</p>
          </div>
        </div>
        <div class="box_content">
          <div class="content_code">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-qrcode-demo"></use>
            </svg>
          </div>
        </div>
        <div class="box_bottom">
          <div class="bottom_text">
            <span>{{ $t("label.campaign.channel.ticket.mark") }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as CommonObjApi from "../../../../api";

export default {
  name: "stepOne",
  data() {
    return {
      isShowSample: false, // 是否展示示例
      isShowTicket: false, // 是否展示入场券
      labelPosition: "right", // 标签位置
      isUrlDisabled: true, // 是否可编辑url
      isImgDisabled: true, // 是否可以上传图片
      stepOneformData: {
        channel: "", // 渠道名称
        validdate: "", // 截止时间 有效期
        sjcb: "", // 实际成本
        leadowner: "campaign", // 渠道负责人
        rtnType: "", // 表单提交成功提示
        rtnurl: "",
        // picList:[],
        isinformowner: "", //成员签到是否通知负责人
        // isvalidatecode:'', // 是否进行手机号码验证
        isactivity: true, // 是否启用
        imgId: "", // 图片
      },
      successTipList: [
        { label: this.$i18n.t("label.web.link"), id: "link" },
        { label: this.$i18n.t("label.campaign.channel.upload.img"), id: "pic" },
        {
          label: this.$i18n.t("label.campaign.channel.input.ticket"),
          id: "ticket",
        },
      ],
      leadownerList: [
        {
          label: this.$i18n.t("label.all.marketing.activities"),
          value: "campaign",
        },
        {
          label: this.$i18n.t("label.channel.invites.employees"),
          value: "channel",
        },
      ],
      rules: {
        channel: [
          {
            required: true,
            message: this.$i18n.t("label.please.enter.channel.name"),
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        validdate: [
          {
            // type: 'date',
            required: true,
            message: this.$i18n.t("label.select.deadlineplease.deadline"),
            trgger: "change",
          },
        ],
      },
      fileType: ["jpg", "png", "jpeg"], // 上传图片的类型
      fileList: [],
      token: this.$CCDK.CCToken.getToken(),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 3600 * 1000 * 24; // 禁用当前日期以前的日期
          // return time.getTime() <= Date.now();    //禁用今天以及以前的日期
        },
      },
    };
  },
  mounted() {
    // 第一步新建的时候不用调用接口
    if (this.$store.state.channel.actionType == "edit") {
      this.getStepOneData();
    }
  },
  methods: {
    // 选中网页链接
    urlChange() {
      this.isUrlDisabled = false;
      this.isImgDisabled = true;
      this.stepOneformData.imgId = "";
    },
    // 选中上传图片
    imgChange() {
      this.isImgDisabled = false;
      this.isUrlDisabled = true;
      this.stepOneformData.rtnurl = "";
    },
    //  选中入场券
    ticketChange() {
      this.isUrlDisabled = true;
      this.isImgDisabled = true;
      this.stepOneformData.imgId = "";
      this.stepOneformData.rtnurl = "";
    },
    lookSample(type) {
      if (type == "sample") {
        this.isShowSample = true;
      } else if (type == "ticket") {
        this.isShowTicket = true;
      }
    },
    // 获取步骤一页面的数据
    getStepOneData() {
      let params;
      params = { id: this.$store.state.channel.itemDataId };
      CommonObjApi.stepOne(params)
        .then((res) => {
          if (res.result) {
            // 回显表单的值
            if (res.data) {
              this.stepOneformData.rtnType = res.data.rtntype;
              if (res.data.rtntype == "ticket") {
                this.isUrlDisabled = true;
                this.isImgDisabled = true;
              } else if (res.data.rtntype == "url") {
                this.isUrlDisabled = false;
                this.isImgDisabled = true;
                this.stepOneformData.rtnurl =
                  res.data.rtnurl == null ? "" : res.data.rtnurl;
              } else {
                // image
                this.stepOneformData.imgId =
                  res.data.rtnurl == null ? "" : res.data.rtnurl;
                this.isUrlDisabled = true;
                this.isImgDisabled = false;
              }
              this.stepOneformData.channel = res.data.channel;
              this.stepOneformData.validdate = res.data.validdate;
              this.stepOneformData.sjcb = res.data.sjcb;
              this.stepOneformData.leadowner = res.data.leadowner;
              this.stepOneformData.isinformowner = JSON.parse(
                res.data.isinformowner
              );
              this.stepOneformData.isactivity = JSON.parse(res.data.isactivity); // 将字符串转成布尔类型
            }
          }
        })
        .catch(() => {
          return false;
        });
    },
    //
    submitForm() {
      let validate = false;
      // 验证
      this.$refs.stepOneForm.validate((valid) => {
        if (valid) {
          validate = true;
          // alert('submit!');
        } else {
          validate = false;
        }
      });
      return validate;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      let that = this;
      if (!isLt2M) {
        this.$message({
          message: this.$i18n.t("label.upload.file.size.limit"),
          type: "warning",
        });
        // this.$message.error(file.name + this.$i18n.t('vue_label_file_over_file_size'))
        return false;
      } else if (this.fileType.indexOf(type) === -1) {
        this.$message.error(
          this.$message.error(
            this.$i18n.t("vue_label_file_wrong_type", { type: type })
          )
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");
        CommonObjApi.uploadImg(formData)
          .then((res) => {
            if (res.result && res.data.fileid) {
              that.stepOneformData.imgId = res.data.fileid;
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    handleSuccess() {},
    // 文件列表移除文件时的钩子
    handleRemove() {},
    // 点击文件列表中已上传的文件时的钩子
    handlePreview() {},
    // 文件超出个数的限制
    handleExceed(files, fileList) {
      this.$message.warning(
        `${this.$i18n.t("label.limiting.choose.three.files")} ${
          files.length
        } ${this.$i18n.t("label.total.choice")} ${
          files.length + fileList.length
        } ${this.$i18n.t("label.file")}`
      );
    },
    // 删除文件之前的钩子
    beforeRemove(file) {
      return this.$confirm(
        `${this.$i18n("label.confirm.to.remove")} ${file.name}？`
      );
    },
    //清除图片
    clearPic() {
      this.stepOneformData.imgId = "";
      // this.$refs.upload.clearFiles()
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  color: #606266;
  line-height: 40px;
  padding: 0px 8px 0 0;
  box-sizing: border-box;
}
.base-form {
  font-size: 12px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  font-size: 12px;
}
::v-deep .el-radio {
  color: #080707;
  cursor: pointer;
}
::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
.successTipItemBox {
  width: 100%;
  .successTipItem {
    display: flex;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #080707;
    .successTipRadio {
      margin-right: 15px;
    }
  }
  .aStyle {
    font-size: 14px;
    color: #006dcc;
  }
  .upload {
    display: flex;
  }
}
::v-deep .el-button {
  font-size: 14px;
}
.ml15 {
  margin-left: 8px;
}
.mt15 {
  margin-bottom: 15px;
}
.picBox {
  width: 300px;
  height: 200px;
  margin-left: 25px;
  margin-bottom: 22px;
}
::v-deep .el-select {
  width: 100%;
}
// 入场券样式
.ticketBox {
  .tag {
    text-align: center;
    font-family: "Microsoft Yahei";
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .box_top {
    width: 98%;
    background: url(https://testdev.cloudcc.com/platform/qrcode/images/bg_top.jpg)
      repeat-x left top;
    background-color: #ffffff;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-align: center;
    border-bottom: 1px dashed #cccccc;
    .top_title {
      width: 100%;
    }
    .top_title h3 {
      color: #52a3fe;
      font-weight: 400;
      /* line-height: 2.2rem; */
      padding-top: 20px;
    }
    .top_title p {
      font-size: 0.8rem;
      color: #666666;
    }
  }
  .box_content {
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    height: initial;
    width: 98%;
    margin: auto;
    .content_code {
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      width: 90%;
      margin: auto;
      display: flex;
      justify-content: center;
      .icon {
        width: 170px;
        height: 170px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .box_bottom {
    width: 98%;
    background: url(https://testdev.cloudcc.com/platform/qrcode/images/bg_bottom.jpg)
      repeat-x left bottom;
    background-color: white;
    border-top: 1px dashed #cccccc;
    margin: auto;
    .bottom_text {
      padding: 0 1rem;
      padding-top: 10px;
      padding-left: 10px;
      padding-bottom: 15px;
      margin: auto;
      .bottom_text span {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
