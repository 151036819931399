<template>
  <div class="base-form">
    <el-form
      :status-icon="true"
      ref="stepSevenForm"
      :model="stepSevenformData"
      class="clearfix"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
    >
      <div class="pull-left" style="width: 100%">
        <p class="groupTitle">{{ $t("label.registration.page.invitation") }}</p>
      </div>
      <div class="pull-left" style="width: 100%">
        <p class="explain">{{ $t("label.campaign.channel.step6.remark") }}</p>
      </div>
      <div class="pull-left" style="width: 100%">
        <el-form-item style="margin-left: 50px">
          <div class="successTipItemBox">
            <div class="successTipItem">
              <div class="successTipRadio">
                <el-checkbox v-model="stepSevenformData.isusing">{{
                  $t("label.campaign.channel.step6.remark2")
                }}</el-checkbox>
              </div>
            </div>
            <div class="successTipItem">
              <p style="margin-left: 25px">
                {{ $t("label.employees.only.invitation.code") }}
                <a @click="visitFn">{{
                  $t("label.campaign.channel.step6.remark6")
                }}</a>
              </p>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="buttonBox" style="width: 100%">
        <el-button @click="changePage('mobile')" size="small">{{
          $t("label.generate.mobile.page")
        }}</el-button>
        <el-button @click="changePage('pc')" size="small">{{
          $t("label.completed.generated.page")
        }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "stepSeven",
  props: {},
  data() {
    return {
      labelPosition: "right", // 标签位置
      stepSevenformData: {
        isusing: "", // 是否启用员工邀约功能
        showType: "", // 移动端还是PC端
      },
    };
  },
  mounted() {},
  methods: {
    // 生成移动端还是pc端页面
    changePage(pageType) {
      this.stepSevenformData.showType = pageType;
      // 调用保存接口
      this.$emit("save");
    },
    visitFn() {
      window.open("https://www.cloudcc.com/en/404.html");
    },
  },
};
</script>

<style scoped lang="scss">
.base-form {
  font-size: 12px;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  font-size: 12px;
}
::v-deep .el-radio {
  color: #080707;
  cursor: pointer;
}
::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
.successTipItemBox {
  width: 100%;
  .successTipItem {
    display: flex;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #080707;
    .successTipRadio {
      margin-right: 15px;
    }
  }
  .aStyle {
    font-size: 14px;
    color: #006dcc;
  }
  .upload {
    display: flex;
  }
}
::v-deep .el-button {
  font-size: 14px;
}
.ml15 {
  margin-left: 10px;
}
.mt15 {
  margin-bottom: 15px;
}
.explain {
  font-size: 14px;
  color: #080707;
  margin-left: 30px;
  margin-bottom: 0;
}
::v-deep .el-checkbox ::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #080707;
}
.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>